
import { defineComponent } from 'vue'
import PageSingle from '@/components/layout/PageSingle.vue'
import SamplePrivacyContent from '@/components/pages/sample/SamplePrivacyContent.vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: {
    TmButton,
    SamplePrivacyContent,
    PageSingle,
  },
})
